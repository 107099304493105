import { gql } from "@apollo/client";
import { Classes, PortalProvider } from "@blueprintjs/core";
import {
  AppTheme,
  HexId,
  HexVersionString,
  OrgRole,
  getCustomFontUrl,
  randomString,
} from "@hex/common";
import React, { useContext, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { ThemeProvider, createGlobalStyle, css } from "styled-components";

import { popoverStyles } from "./hex-components/HexPopover.js";
import { useCurrentUser } from "./hooks/me/useCurrentUser";
import { useGetThemeTypeWithPreference } from "./hooks/useGetThemeFromName";
import { LocalStorageKeys, useLocalStorage } from "./hooks/useLocalStorage";
import {
  useIsSignedEmbeddingApp,
  useThemeOverride,
} from "./route/routeHooks.js";
import { Routes } from "./route/routes";
import { Theme } from "./theme/common/theme.js";
import { toastStyles } from "./theme/common/toasts.js";
import { getCustomTheme } from "./theme/customThemes.js";
import { DARK_THEME } from "./theme/themes/darkTheme.js";
import { LIGHT_THEME } from "./theme/themes/lightTheme.js";
import {
  OrgPublishedAppThemeQueryFragment,
  useGetHexVersionQuery,
} from "./ThemeWrapper.generated";

gql`
  fragment OrgCustomFontFragment on OrgCustomFont {
    id
    name
    fontDescriptor
  }

  fragment OrgPublishedAppThemeQueryFragment on OrgPublishedAppTheme {
    id
    name
    colorPalette {
      id
      colors
      name
    }
    font {
      id
      ...OrgCustomFontFragment
    }
    styles
  }

  query GetHexVersion($hexId: HexId!, $version: HexVersionString!) {
    hexVersionByNumber(hexId: $hexId, version: $version) {
      id
      appTheme
      customPublishedAppTheme {
        id
        ...OrgPublishedAppThemeQueryFragment
      }
    }
  }
`;

const CssVariableInjector = createGlobalStyle<{
  theme: Theme;
}>`
  body {
    ${({ theme }) => theme.globalCSSVars}
  }
`;

export const ThemeResetVariables = css`
  ${({ theme }) => theme.globalCSSVars}
  font-family: ${({ theme }) => theme.fontFamily.DEFAULT};
`;

const PortalCustomStyles = createGlobalStyle<{
  theme: Theme;
  className: string;
}>`
  .${({ className }) => className} .${Classes.POPOVER} {
    ${ThemeResetVariables}
    ${popoverStyles}
  }

  .${({ className }) => className} {
    ${toastStyles}

    .${Classes.TOAST} {
      font-family: ${({ theme }) => theme.fontFamily.DEFAULT};
    }
  }
`;

export const ThemeOptionContext = React.createContext<{
  custom: Theme;
  base: Theme;
  refetch: () => Promise<unknown>;
  customPortalClassName: string;
} | null>(null);

export const PortalClassNameContext = React.createContext<string | undefined>(
  undefined,
);

/**
 * A react provider that allows sub elements to opt in and opt out of the base theme and or custom published app theme
 * This does not load themes from hexVersions, just toggles between custom and base themes
 *
 * If using ThemeOverrideWrapper, will need to inject ThemeResetVariables to the child div to set override css variables
 */
export const ThemeOverrideWrapper: React.FC<{
  theme: "custom" | "base";
}> = React.memo(function ThemeOverrideWrapper({ children, theme }) {
  const themeOptions = useContext(ThemeOptionContext);

  if (!themeOptions) {
    return <>{children}</>;
  }

  if (theme === "custom") {
    return (
      <ThemeProvider theme={themeOptions.custom}>
        <PortalClassNameContext.Provider
          value={themeOptions.customPortalClassName}
        >
          <PortalProvider portalClassName={themeOptions.customPortalClassName}>
            {children}
          </PortalProvider>
        </PortalClassNameContext.Provider>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={themeOptions.base}>
        <PortalClassNameContext.Provider value={undefined}>
          <PortalProvider portalClassName={undefined}>
            {children}
          </PortalProvider>
        </PortalClassNameContext.Provider>
      </ThemeProvider>
    );
  }
});

interface ThemeWrapperProps {
  customThemeOverride?: Pick<
    OrgPublishedAppThemeQueryFragment,
    "styles" | "font" | "colorPalette"
  >;
}

const useHexVersionFromRoute = (): {
  hexId: HexId;
  version: HexVersionString;
  context: "app" | "logic";
} | null => {
  const appRouteMatch = useRouteMatch<{
    hexId: HexId;
    version: HexVersionString;
  }>(Routes.APP);
  const logicRouteMatch = useRouteMatch<{ hexId: HexId }>(Routes.LOGIC);

  if (appRouteMatch != null) {
    return {
      hexId: appRouteMatch.params.hexId,
      version: appRouteMatch.params.version,
      context: "app",
    };
  } else if (logicRouteMatch != null) {
    return {
      hexId: logicRouteMatch.params.hexId,
      version: "draft",
      context: "logic",
    };
  } else {
    return null;
  }
};

const FontLoader = React.memo(function FontLoader({
  customTheme,
}: {
  customTheme?: Pick<
    OrgPublishedAppThemeQueryFragment,
    "styles" | "font" | "colorPalette"
  > | null;
}) {
  const urlToLoad: string | null = useMemo(() => {
    if (customTheme == null) {
      return null;
    }

    return getCustomFontUrl(
      customTheme.styles.font,
      customTheme.font?.fontDescriptor,
    );
  }, [customTheme]);

  return urlToLoad ? <link href={urlToLoad} rel="stylesheet" /> : null;
});

const useQueryHexVersionTheme = (
  customThemeOverride?: Pick<
    OrgPublishedAppThemeQueryFragment,
    "styles" | "font" | "colorPalette"
  >,
): {
  hexVersionAppTheme: AppTheme | null;
  customHexVersionTheme: Pick<
    OrgPublishedAppThemeQueryFragment,
    "styles" | "font" | "colorPalette"
  > | null;
  context: "app" | "logic" | null;
  refetch: () => Promise<unknown>;
  loading: boolean;
} => {
  const hexVersionFromRoute = useHexVersionFromRoute();
  const hexId: HexId = hexVersionFromRoute?.hexId ?? ("" as HexId);
  const version: HexVersionString =
    hexVersionFromRoute?.version ?? ("" as HexVersionString);

  const { data, loading, refetch } = useGetHexVersionQuery({
    skip: hexVersionFromRoute == null,
    variables: {
      hexId,
      version,
    },
  });
  const hexVersionAppTheme = data?.hexVersionByNumber.appTheme ?? null;
  const customHexVersionTheme =
    customThemeOverride ??
    data?.hexVersionByNumber.customPublishedAppTheme ??
    null;

  return {
    hexVersionAppTheme,
    customHexVersionTheme,
    refetch,
    context: hexVersionFromRoute?.context ?? null,
    loading,
  };
};

const useThemeName = (
  hexVersionAppTheme: AppTheme | null,
): Exclude<AppTheme, "SYS_PREF"> => {
  const isAnonymous = useCurrentUser()?.orgRole === OrgRole.ANONYMOUS;
  const [userThemeName] = useLocalStorage(LocalStorageKeys.THEME_NAME);
  const themeName = isAnonymous
    ? hexVersionAppTheme ?? userThemeName
    : userThemeName;

  const resolvedTheme = useThemeOverride() ?? themeName;
  const baseThemeType = useGetThemeTypeWithPreference(resolvedTheme);

  return baseThemeType;
};

const ThemeWrapper: React.FunctionComponent<ThemeWrapperProps> = ({
  children,
  customThemeOverride,
}) => {
  const isSignedEmbeddedApp = useIsSignedEmbeddingApp();
  const {
    context,
    customHexVersionTheme,
    hexVersionAppTheme,
    loading,
    refetch,
  } = useQueryHexVersionTheme(customThemeOverride);
  const customPortalClassName = useMemo(
    () => `nested-portal-reset-${randomString(5)}`,
    [],
  );
  const baseThemeName = useThemeName(hexVersionAppTheme);
  // When we use a custom theme, we want to use the custom theme's inferred light or dark mode to choose the Hex wrapper's theme around the published app
  // We only want to do this in the published app view.
  const computedThemes = useMemo(() => {
    const baseTheme = baseThemeName === "DARK" ? DARK_THEME : LIGHT_THEME;
    const maybeCustomTheme = customHexVersionTheme
      ? getCustomTheme(
          customHexVersionTheme,
          baseThemeName === "DARK" ? "dark" : "light",
        )
      : null;

    if (maybeCustomTheme) {
      return {
        custom: maybeCustomTheme.customTheme,
        base:
          context === "app"
            ? maybeCustomTheme.isLight
              ? LIGHT_THEME
              : DARK_THEME
            : baseTheme,
      };
    } else {
      return {
        custom: baseTheme,
        base: baseTheme,
      };
    }
  }, [baseThemeName, context, customHexVersionTheme]);

  const themeOptionContextValue = useMemo(() => {
    return {
      custom: computedThemes.custom,
      base: computedThemes.base,
      refetch,
      customPortalClassName,
    };
  }, [computedThemes, refetch, customPortalClassName]);

  // If there is an error, we'll fallback to a hex theme
  if (loading) {
    return null;
  }

  return (
    <ThemeProvider
      theme={isSignedEmbeddedApp ? computedThemes.custom : computedThemes.base}
    >
      <ThemeOptionContext.Provider value={themeOptionContextValue}>
        <FontLoader customTheme={customHexVersionTheme} />
        {/*
          We skip global style injection when using a custom theme override, for some reason
          styled component global injection is incredibly slow and we don't need custom css styles
          when passing a customHexThemeOverride as they're called multiple times in the same app
        */}
        {!customThemeOverride && (
          <>
            <CssVariableInjector />
            {customHexVersionTheme && (
              <PortalCustomStyles
                className={customPortalClassName}
                theme={computedThemes.custom}
              />
            )}
          </>
        )}
        {children}
      </ThemeOptionContext.Provider>
    </ThemeProvider>
  );
};

export default ThemeWrapper;
